import { WalletLinkConnector } from "@web3-react/walletlink-connector";
import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";

export const walletlink = new WalletLinkConnector({
  url: "https://mainnet.infura.io/v3/5bdaf7c2657d4e7e96f6088f67f59883",
  appName: "My NFT Collection",
  supportedChainIds: [1, 4, 80001, 137],
});

export const walletInjected = new InjectedConnector({
  supportedChainIds: [1, 4, 80001, 137],
});

export const walletConnect = new WalletConnectConnector({
  infuraId: "5bdaf7c2657d4e7e96f6088f67f59883",
  supportedChainIds: [1, 4, 80001, 137],
  qrcode: true,
});
