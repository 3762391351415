import React, { useEffect, useState } from "react";
import { Button, Icon } from "@mui/material";
import { useWeb3React } from "@web3-react/core";
import * as Panelbear from "@panelbear/panelbear-js";

import CoinbaseWalletLogo from "assets/wallet/coinbase-wallet-logo.svg";
import { walletlink } from "./Connectors";

function WalletLink({ forceShow }: { forceShow: boolean }): JSX.Element {
  const [connectWithWallet, setConnectWithWallet] = useState(false);
  const { active, account, activate, deactivate } = useWeb3React();

  async function connect() {
    try {
      await activate(walletlink);
      setConnectWithWallet(true);
      Panelbear.track("WalletConnectConnection");
    } catch (ex) {
      console.log(ex);
    }
  }

  async function disconnect() {
    try {
      deactivate();
      setConnectWithWallet(false);
    } catch (ex) {
      console.log(ex);
    }
  }

  useEffect(() => {
    if (forceShow && !active) {
      connect();
    }
  }, [account]);

  if (active && !connectWithWallet) {
    return <span></span>;
  }

  return (
    <React.Fragment>
      {!connectWithWallet ? (
        <Button variant="outlined" onClick={connect} className="w-full md:w-80">
          Connect to Coinbase Wallet
          <Icon className="ml-6">
            <img alt="coinbase-wallet-connect" src={CoinbaseWalletLogo} />
          </Icon>
        </Button>
      ) : (
        <Button
          variant="outlined"
          onClick={disconnect}
          className="w-full md:w-80"
        >
          Disconnect to Coinbase Wallet
          <Icon className="ml-6">
            <img alt="coinbase-wallet-connect" src={CoinbaseWalletLogo} />
          </Icon>
        </Button>
      )}
    </React.Fragment>
  );
}

export default WalletLink;
