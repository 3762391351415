import React from "react";

import { Grid } from "@mui/material";

import WalletConnect from "./common/WalletConnect";
import WalletLink from "./common/WalletLink";
import WalletInjector from "./common/WalletInjector";

function WalletsConnectors(): JSX.Element {
  return (
    <React.Fragment>
      <Grid item xs={12} md={12} className="flex justify-center">
        <WalletConnect forceShow={false} />
      </Grid>
      <Grid item xs={12} md={12} className="flex justify-center">
        <WalletLink forceShow={false} />
      </Grid>
      <Grid item xs={12} md={12} className="flex justify-center">
        <WalletInjector forceShow={false} />
      </Grid>
    </React.Fragment>
  );
}

export default WalletsConnectors;
