import React, { useEffect, useState } from "react";

import { useWeb3React } from "@web3-react/core";
import { walletInjected } from "./Connectors";

import { Button, Icon } from "@mui/material";

import MetamaskLogoWallet from "assets/wallet/metamask-logo.svg";

import * as Panelbear from "@panelbear/panelbear-js";

function WalletInjector({ forceShow }: { forceShow: boolean }): JSX.Element {
  const [connectWithWallet, setConnectWithWallet] = useState(false);
  const { active, account, activate, deactivate } = useWeb3React();

  async function connect() {
    try {
      await activate(walletInjected);
      setConnectWithWallet(true);
      Panelbear.track("WalletInjectorConnection");
    } catch (ex) {
      console.log(ex);
    }
  }

  async function disconnect() {
    try {
      deactivate();
      setConnectWithWallet(false);
    } catch (ex) {
      console.log(ex);
    }
  }

  useEffect(() => {
    if (forceShow && !active) {
      connect();
    }
  }, [account]);

  if (active && !connectWithWallet) {
    return <span></span>;
  }

  return (
    <React.Fragment>
      {!connectWithWallet ? (
        <Button variant="outlined" onClick={connect} className="w-full md:w-80">
          Connect with browser wallet
          <Icon className="ml-3">
            <img alt="metamask-wallet-connect" src={MetamaskLogoWallet} />
          </Icon>
        </Button>
      ) : (
        <Button
          variant="outlined"
          onClick={disconnect}
          className="w-full md:w-80"
        >
          Disconnect from wallet
          <Icon className="ml-3">
            <img alt="metamask-wallet-connect" src={MetamaskLogoWallet} />
          </Icon>
        </Button>
      )}
    </React.Fragment>
  );
}

export default WalletInjector;
