import { ERC721Standard, Network } from "common/hooks/useNft";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Icon from "@mui/material/Icon";

import EthLogo from "assets/chain/eth.svg";
import RinkebyLogo from "assets/chain/rinkeby.svg";
import PolygonLogo from "assets/chain/polygon.svg";

function NFTShowCase({
  NFTMetadata,
}: {
  NFTMetadata: ERC721Standard;
}): JSX.Element {
  function renderNetworkLogo() {
    switch (NFTMetadata.network) {
      case Network.ether:
        return <img alt="chain-logo" src={EthLogo} />;
      case Network.rinkeby:
        return <img alt="chain-logo" src={RinkebyLogo} />;
      case Network.polygon:
        return <img alt="chain-logo" src={PolygonLogo} />;
      default:
        return <span></span>;
    }
  }

  return (
    <Card sx={{ maxWidth: "40rem", height: "100%" }}>
      <CardMedia
        component="img"
        image={NFTMetadata.image}
        alt={NFTMetadata.name}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          <Icon className="mr-1">{renderNetworkLogo()}</Icon>
          {NFTMetadata.name}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {NFTMetadata.description}
        </Typography>
      </CardContent>
    </Card>
  );
}

export default NFTShowCase;
