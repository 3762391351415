import Web3 from "web3";
import { Web3ReactProvider } from "@web3-react/core";
import { provider } from "web3-core";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import ThemeConfig from "styles/theme/ThemeConfig";
import NTFCollection from "pages/NTFCollection";

function getLibrary(provider: provider): Web3 {
  return new Web3(provider);
}

function App(): JSX.Element {
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <ThemeProvider theme={ThemeConfig}>
        <CssBaseline />
        <NTFCollection />
      </ThemeProvider>
    </Web3ReactProvider>
  );
}

export default App;
