import axios from "axios";
import { useEffect, useState } from "react";

import { ERC721Response, NFTTransaction } from "common/hooks/useETHTransaction";
import { Network } from "common/hooks/useNft";

export function usePolygonTransaction(
  account?: string | null
): [NFTTransaction, boolean] {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [tokens, setTokens] = useState<NFTTransaction>({
    tokens: [],
    network: Network.polygon,
  });

  useEffect(() => {
    async function getAccountMintNFT() {
      const url = `https://api.polygonscan.com/api?module=account&action=tokennfttx&address=${account}&startblock=0&endblock=99999999&page=1&offset=100&sort=desc&apikey=${process.env.REACT_APP_POLYGON_SCAN_KEY}`;

      const response = await axios.get<ERC721Response>(url);

      const ERC721Token = response.data.result;

      setTokens({ tokens: ERC721Token, network: Network.polygon });
      setIsLoading(false);
    }

    if (account) {
      setIsLoading(true);
      getAccountMintNFT();
    }
  }, [account]);

  return [tokens, isLoading];
}
