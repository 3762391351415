import { useWeb3React } from "@web3-react/core";

import { Grid, Box, CircularProgress, Typography } from "@mui/material";
import React from "react";
import Carousel from "react-elastic-carousel";

import {
  NFTTransaction,
  useETHTransaction,
} from "common/hooks/useETHTransaction";
import { useRinkebyTransaction } from "common/hooks/useRinkebyTransaction";
import { usePolygonTransaction } from "common/hooks/usePolygonTransaction";
import { ERC721Standard, useNFT } from "common/hooks/useNft";

import WalletsConnectors from "components/wallet/WalletsConnectors";
import NFTShowCase from "components/NFTShowCase";

import AppLogo from "assets/logo/logo-512.png";

function NTFCollection(): JSX.Element {
  const { active, account } = useWeb3React();

  const [rinkeybyNFTTransaction, isTestLoading]: [
    NFTTransaction,
    boolean
  ] = useRinkebyTransaction(account);

  const [ethNFTTransaction, isLoading]: [
    NFTTransaction,
    boolean
  ] = useETHTransaction(account);

  const [polygonNFTTransaction, isPolygonLoading]: [
    NFTTransaction,
    boolean
  ] = usePolygonTransaction(account);

  const [ethTokensMetaData, ethLoadingTokensMetaData]: [
    ERC721Standard[],
    boolean
  ] = useNFT(ethNFTTransaction);

  const [rinkeybyTokensMetaData, rinkeybyLoadingTokensMetaData]: [
    ERC721Standard[],
    boolean
  ] = useNFT(rinkeybyNFTTransaction);

  const [polygonTokensMetaData, polygonLoadingTokensMetaData]: [
    ERC721Standard[],
    boolean
  ] = useNFT(polygonNFTTransaction);

  const loading =
    isLoading ||
    isTestLoading ||
    ethLoadingTokensMetaData ||
    isPolygonLoading ||
    polygonLoadingTokensMetaData ||
    rinkeybyLoadingTokensMetaData;

  function renderCollection(): JSX.Element {
    if (!active) {
      return (
        <Grid item xs={12}>
          <Typography variant="h6" align="center">
            Connect you account to show you NFT 🎉{" "}
          </Typography>
        </Grid>
      );
    }

    if (loading) {
      return (
        <Grid item xs={12} sx={{ flex: "none !important" }}>
          <CircularProgress />
        </Grid>
      );
    }

    if (
      ethTokensMetaData.length === 0 &&
      rinkeybyTokensMetaData.length === 0 &&
      polygonTokensMetaData.length === 0
    ) {
      return (
        <Grid item xs={12}>
          <Typography variant="h6" align="center">
            {" "}
            😭 You have no NFT for this account ! Try a other account.{" "}
          </Typography>
        </Grid>
      );
    }

    return (
      <Grid item xs={12}>
        <Box className="h-full">
          <Carousel
            itemsToShow={1}
            isRTL={false}
            showArrows={false}
            enableAutoPlay={true}
            autoPlaySpeed={5000}
          >
            {ethTokensMetaData.map((tokenMetaData, index) => (
              <NFTShowCase key={index} NFTMetadata={tokenMetaData} />
            ))}
            {rinkeybyTokensMetaData.map((tokenMetaData, index) => (
              <NFTShowCase key={index} NFTMetadata={tokenMetaData} />
            ))}
            {polygonTokensMetaData.map((tokenMetaData, index) => (
              <NFTShowCase key={index} NFTMetadata={tokenMetaData} />
            ))}
          </Carousel>
        </Box>
      </Grid>
    );
  }

  return (
    <React.Fragment>
      <Grid
        container
        mt={2}
        alignItems="center"
        justifyContent="center"
        style={{ height: "90vh" }}
      >
        {renderCollection()}
        <Grid container alignItems="center" spacing={2} justifyContent="center">
          <WalletsConnectors />
        </Grid>
      </Grid>

      <Box mt={2} className="absolute bottom-2 left-2">
        <Typography
          className="font-bold"
          component="span"
          variant="body2"
          align="left"
        >
          <a
            href="https://www.privacypolicytemplate.net/live.php?token=zxOjlmUQ5Jpr8ZthYEBG9fiIDypVFyhi"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </a>
        </Typography>
        <Typography component="span"> | </Typography>
        <Typography
          className="font-bold"
          component="span"
          variant="body2"
          align="left"
        >
          <a
            href="https://www.privacypolicyonline.com/live.php?token=4VWnvU84QyiqeUqCmSv2XupRL8k4n8Ir"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms and Conditions
          </a>
        </Typography>
      </Box>

      <Box mr={2} className="absolute bottom-0 right-0">
        <a href="https://justinbrulotte.com" target="_blank">
          <img
            className="h-16 inline-block object-contain"
            alt="My digital collection logo"
            src={AppLogo}
          />
          <Typography
            className="font-bold"
            component="span"
            variant="body2"
            align="right"
          >
            My NFT collection
          </Typography>
        </a>
      </Box>
    </React.Fragment>
  );
}

export default NTFCollection;
