import { createTheme, ThemeOptions } from "@mui/material/styles";

export const themeOptions: ThemeOptions = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 768,
      lg: 1024,
      xl: 1280,
    },
  },
  palette: {
    mode: "dark",
    primary: {
      main: "#e4e4e7",
    },
    secondary: {
      main: "#d62828",
    },
    warning: {
      main: "#fcbf49",
    },
    info: {
      main: "#eae2b7",
    },
  },
  typography: {
    fontFamily: [
      "HelveticaNeue-Light",
      "Helvetica Neue Light",
      "Helvetica",
    ].join(","),
  },
};
const ThemeConfig = createTheme(themeOptions);

export default ThemeConfig;
